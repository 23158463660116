var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-border tw-p-3 tw-rounded tw-shadow"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 tw-space-y-3"},[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-3 col-form-label tw-text-right required"},[_vm._v(_vm._s(_vm.$t("serviceQuotation.quotationDate")))]),_c('div',{staticClass:"col-md-6"},[_c('DatePicker',{staticStyle:{"width":"100%"},attrs:{"value":_vm.model.quotation_date,"type":"date","placeholder":"DD-MM-YYYY","format":"dd-MM-yyyy","clearable":false}}),(_vm.errors.has('quotation_date'))?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first("quotation_date"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-3 col-form-label tw-text-right"},[_vm._v(_vm._s(_vm.$t("serviceQuotation.customer")))]),_c('div',{staticClass:"col-md-6"},[_c('input',{staticClass:"form-control",attrs:{"readonly":"","type":"text"},domProps:{"value":_vm.model.service_ticket_info
                        ? _vm.model.service_ticket_info.customer
                            ? _vm.model.service_ticket_info.customer
                                .customer_name
                            : null
                        : null}}),(_vm.errors.has('customer_id'))?_c('div',{staticClass:"validate"},[_vm._v(" "+_vm._s(_vm.errors.first("customer_id"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-3 col-form-label tw-text-right"},[_vm._v(_vm._s(_vm.$t("serviceQuotation.servedBy")))]),_c('div',{staticClass:"col-md-6"},[_c('input',{staticClass:"form-control",attrs:{"readonly":"","type":"text"},domProps:{"value":_vm.model.service_ticket_info
                        ? _vm.model.service_ticket_info.served_by_name
                            ? _vm.model.service_ticket_info
                                .served_by_name.employee
                                ? _vm.model.service_ticket_info
                                    .served_by_name.employee
                                    .employee_name_en
                                : null
                            : null
                        : null}}),(_vm.errors.has('credit_term'))?_c('div',{staticClass:"validate"},[_vm._v(" "+_vm._s(_vm.errors.first("credit_term"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-3 col-form-label tw-text-right"},[_vm._v(_vm._s(_vm.$t("serviceQuotation.repairedBy")))]),_c('div',{staticClass:"col-md-6"},[_c('input',{staticClass:"form-control",attrs:{"readonly":"","type":"text"},domProps:{"value":_vm.model.service_ticket_info
                        ? _vm.model.service_ticket_info
                            .ticket_owner_name
                            ? _vm.model.service_ticket_info
                                .ticket_owner_name
                                .employee_name_en
                            : null
                        : null}}),(_vm.errors.has('valid_until'))?_c('div',{staticClass:"validate"},[_vm._v(" "+_vm._s(_vm.errors.first("valid_until"))+" ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-6 tw-space-y-3"},[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-3 col-form-label tw-text-right required"},[_vm._v(_vm._s(_vm.$t("serviceQuotation.ticketNo")))]),_c('div',{staticClass:"col-md-6"},[_c('Select',{attrs:{"filter-by-label":true,"placeholder":_vm.$t('select'),"clearable":true,"filterable":""},on:{"on-change":_vm.onChangeServiceTicket,"on-query-change":_vm.handleSearchServiceTicket},model:{value:(_vm.model.service_ticket_id),callback:function ($$v) {_vm.$set(_vm.model, "service_ticket_id", $$v)},expression:"model.service_ticket_id"}},_vm._l((_vm.serviceTickets),function(serviceTicket){return _c('Option',{key:serviceTicket.service_ticket_id,attrs:{"value":serviceTicket.service_ticket_id,"label":serviceTicket.ticket_no}},[_vm._v(" "+_vm._s(serviceTicket.ticket_no)+" ")])}),1),(_vm.errors.has('service_ticket_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("service_ticket_id"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-3 col-form-label tw-text-right required"},[_vm._v(_vm._s(_vm.$t("serviceQuotation.validDate")))]),_c('div',{staticClass:"col-md-6"},[_c('DatePicker',{staticStyle:{"width":"100%"},attrs:{"value":_vm.model.valid_date,"type":"date","placeholder":"DD-MM-YYYY","format":"dd-MM-yyyy","clearable":false}}),(_vm.errors.has('valid_date'))?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first("valid_date"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-3 col-form-label tw-text-right"},[_vm._v(_vm._s(_vm.$t("serviceQuotation.customerPhone")))]),_c('div',{staticClass:"col-md-6"},[_c('input',{staticClass:"form-control",attrs:{"readonly":"","type":"text"},domProps:{"value":_vm.model.service_ticket_info
                        ? _vm.model.service_ticket_info.customer
                            ? _vm.model.service_ticket_info.customer
                                .contact_number
                            : null
                        : null}}),(_vm.errors.has('pic'))?_c('div',{staticClass:"validate"},[_vm._v(" "+_vm._s(_vm.errors.first("pic"))+" ")]):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }